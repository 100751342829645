
const CART_KEY = 'cart';

function getCurrentCart() {
  let currentCart = {};
  if (typeof window !== "undefined") {
    try {
      const json = localStorage.getItem(CART_KEY)
      currentCart = JSON.parse(json) || {};
    } catch (error) {
      console.error(error);
    }
  }
  return currentCart;
}

let listeners = [];
const updateListenersOnChange = (cart) => {
  console.log({listeners})
  listeners.forEach((listener) => listener(cart));
};
const getCartKeyFromProduct = (product) => {
  const cartKey = product?.purchaseInfo?.seller?.key;
  return cartKey;
}

let actions = {
  updateProductAmount: ({product, quantity}) => {
    const productKey = product.key;
    const cartKey = getCartKeyFromProduct(product)
    
    const cart = getCurrentCart();
    const amountInt = parseInt(quantity);
    const sellerCart = cart[cartKey];

    if (!sellerCart && amountInt) {
      cart[cartKey] = {
        [productKey]: amountInt,
      };
    } 
    else if (sellerCart && amountInt === 0) {
      delete sellerCart[productKey];
    }
    else {
      sellerCart[productKey] = amountInt === 0 ? undefined : amountInt;
    }

    const json = JSON.stringify(cart);
    localStorage.setItem(CART_KEY, json);

    updateListenersOnChange(cart);
    const checkoutUrl = `/checkout/${cartKey}`;
    return {cart, checkoutUrl};
  },
  getProductAmount: ({product }) => {
    if (!product) { return 0;}
    const cart = getCurrentCart();
    const sellerCartKey = getCartKeyFromProduct(product);
    const sellerCart = cart[sellerCartKey];
    let amount = 0;
    if (sellerCart && sellerCart[product.key]) {
      amount = sellerCart[product.key] || 0
    }
    return amount;
  },
  removeSellerItems: (cartKey) => {
    const cart = getCurrentCart();
    delete cart[cartKey];

    const json = JSON.stringify(cart);
    localStorage.setItem(CART_KEY, json);
    updateListenersOnChange();
    return cart;
  },
  isProducerInCart: (cartKey) => {
    const cart = getCurrentCart();
    return cart[cartKey] ? true : false;
  },
  getCurrentCart: () => {
    return getCurrentCart();
  },
  isCurrentCartEmpty: () => {
    const cart = getCurrentCart();
    if (cart === null) {
      return true;
    }
    
    for (const sellerCart of Object.values(cart)) {
      for (const numItems of Object.values(sellerCart)) {
        if (numItems > 0) {
          return false;
        }
      }
    }
    return true;
  },

  getCurrentSellerCart: (cartKey) => {
    return getCurrentCart()[cartKey];
  },

  notifyListenersOnChange: () => {
    updateListenersOnChange(getCurrentCart());
  },
  addChangeListener: (listener) => {
    listeners.push(listener);
  },

  removeChangeListener: () => {
    // console.log({before: listeners.length});
    // listeners = listeners.filter((l) => l == listener);
    // console.log({after: listeners.length});
  }
};

export let Cart = actions;