import { PRODUCT_CATEGORY_SPIRITS, PRODUCT_CATEGORY_WINE } from "@/consts/productConsts";

export const GOODFRIENDS_CA_LINK = "https://vinoshipper.com/shop/goodfriends"

export function makeBrandUrl({ slug, brand, locRef }) {
  if (slug) {
    return `/brand/${slug}?ref=${locRef}`
  }
  if (brand) {
    return `/brand/${brand.key}?ref=${locRef}`
  }
  console.error('slug or brand are mandatory for brand url')
  return '/'
}

export function makeProductUrl({ productKey, product, ref, editMode }) {
  const productK = productKey || product.slug || product.key;

  if (!productK) {
    console.error('product is undefined', product);
    return '';
  }
  if (editMode){
    return `/product/${productK}?edit=true`;
  }
  return ref
    ? `/product/${productK}?ref=${ref}`
    : `/product/${productK}`;
}

export function makeProductsUrlByFilter(filter, productCategory = PRODUCT_CATEGORY_WINE) {
  const filters = JSON.stringify(filter);
  return `/${
    productCategory === PRODUCT_CATEGORY_SPIRITS ? 'spirits' : 'wines'
  }?filters=${filters}`
}
export function makeProductsUrlByType(type, productCategory){
  return makeProductsUrlByFilter({types:[type]}, productCategory);
}

export function makeProductsUrlByWeight(weight, productCategory){
  return makeProductsUrlByFilter({weights:[weight]}, productCategory);
}
export function makeProductsUrlByCountry(country, productCategory){
  return makeProductsUrlByFilter({locations:[country]}, productCategory);
}
export function makeProductsUrlByGrape(grape, productCategory){
  return makeProductsUrlByFilter({grapes:[grape]}, productCategory);
}

export function makeProductsUrlByTopic(topic){
  return `/products?topic=${topic}`;
}

export function makeOrderConfirmationUrl({order}) {
  return window.location.origin + `/confirmation/${order.key}`
}

export function makeOrderCheckoutUrl({ sellerKey, orderKey }) {
  let relativeUrl = `/checkout/${sellerKey}`
  if (orderKey) {
    relativeUrl = `${relativeUrl}?order=${orderKey}`
  }
  return window.location.origin + relativeUrl
}


export function makeReturnUrlAfterStripePayment({order}) {
  const loc = window.location;
  return `${loc.origin}${loc.pathname}?order=${order.key}`;
}