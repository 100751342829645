import mixpanel from 'mixpanel-browser';
import { isProdEnv, isBrowser } from '@/utils/environment';
import Affiliate from './affiliate';
import PartnersSupportClient from '@/utils/partnersSupportClient';

mixpanel.init('19627ba1a69c26086fc8756f5774b319');

let env_check = isProdEnv();

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    const pid = Affiliate.getAffiliate();
    const source = Affiliate.getSource();
    const host = isBrowser && window?.location?.host;
    let ref;
    if (isBrowser) {
      const params = new URL(location.href).searchParams;
      ref = params.get('ref');
    }
    const attr = {
      ...props,
      pid,
      source,
      host,
      ref
    };
    if (env_check) {
      mixpanel.track(name, attr);
    } else {
      console.log(`Mixpanel: ${name}`, attr)
    }
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;