import { createContext, useContext } from "react"
import { getSitePropertiesFromService } from "../services/sipjoyService"

export const SiteContext = createContext({})


export const useSite = () => {
  const ctx = useContext(SiteContext)

  // if (ctx.error) {
  //   throw new Error(
  //     `Error when loading the Current Site Context: ${ctx.error.message}`,
  //     ctx.error,
  //     !IGNORED_ERRORS.includes(ctx.error.message)
  //   )
  // }

  // if (isUndefined(ctx.site)) {
  //   throw new Error(
  //     'useSite must be inside a <SiteContext.Provider /> with a value'
  //   )
  // }

  return ctx || {}
}
export const getPageAppContextProps = (page) => {
  return {
    hideFooter: page?.hide_footer,
    hideTopBar: page?.hide_top_bar,
  }
}

export const generateSiteProperties = async (siteKey) => {
  const siteProperties = await getSitePropertiesFromService({key: siteKey})
  
  return {
    site: siteProperties,
    key: siteProperties.key,
  };
}