import { Mixpanel } from '@/utils/mixpanel';

const AFFILIATE_KEY = 'pid';
const SOURCE_KEY = 'source';
import { genericGet } from '@/src/services/grapeinDtcService';

let Affiliate = (function() {
  let affiliateId = undefined;
  let source = undefined;
  let affiliateData = undefined;

  const getAffiliate = () => {
    if (affiliateId === undefined && localStorage) {
      affiliateId = localStorage.getItem(AFFILIATE_KEY) || null;
    }
    return affiliateId;
  };

  const getSource = () => {
    if (source === undefined && localStorage) {
      source = localStorage.getItem(SOURCE_KEY) || null;
    }
    return source;
  };

  return {
    getAffiliateData:(onResponse, onError) => {
      if (affiliateData) {
        onResponse(affiliateData);
      } else {
        const affiliateId = getAffiliate();
        if (affiliateId) {
          console.log(`Going to fetch affiliate data from server for ${affiliateId}`);
          genericGet(
            'api/affiliate_data',
            { affiliateId },
            (response) => {
              affiliateData = response?.affiliateData;
              onResponse(affiliateData);
            },
            (error) => {
              onError && onError(error);
            },
          );
        }
      }
    },

    getAffiliate: getAffiliate,
    setAffiliate: (affiliate) => {
      const prevAffiliate = getAffiliate();
      if (prevAffiliate !== affiliate) {
        Mixpanel.track('Affiliate override', {
          prevAffiliate,
          newAffiliate: affiliate,
        });
      }
      localStorage.setItem(AFFILIATE_KEY, affiliate);
      affiliateId = affiliate;
    },

    getSource: getSource,
    setSource: (newSource) => {
      const prevSource = getSource();
      if (prevSource !== newSource) {
        Mixpanel.track('Source override', {
          prevSource,
          newSource,
        });
      }
      localStorage.setItem(SOURCE_KEY, newSource);
      source = newSource;
    },
  };
})();

export default Affiliate;
