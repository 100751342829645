
import axios from 'axios'
import {
  getSipjoyServiceAuthorization,
  getSipjoyServiceUrl,
} from '@/utils/environment'

const SERVICE_URL = getSipjoyServiceUrl() || 'http://127.0.0.1:1337'

axios.defaults.headers.common['Authorization'] = getSipjoyServiceAuthorization()

export async function genericPost(relativePath, data) {
  try {
    const res = await axios.post(`${SERVICE_URL}/${relativePath}`, data)
    if (res.status !== 200) {
      throw `Got error status ${res.status}:${res.statusText} from ${url}`
    }
    return await res.data
  } catch (err) {
    const errMsg = err.response?.data?.error?.message;
    console.error(`Error while POST ${relativePath}. ${errMsg}`, data ,err);
    throw errMsg || err;
  }
}

export async function genericGet(relativePath, getParams) {
  const url = `${SERVICE_URL}/${relativePath}`
  try {
    const res = await axios.get(url, {
      params: getParams,
    })
    if (res.status !== 200) {
      throw `Got error status ${res.status}:${res.statusText} from ${url}`
    }
    return await res.data
  } catch (err) {
    const errMsg = err.response?.data?.error?.message;
    console.error(`Error while GET ${url}. ${errMsg}`, getParams ,err);
    throw errMsg || err;
  }
}

export function getSitesToLoadFromService() {
  return genericGet('api/sites-to-load')
}

export function getSitesPagesToLoadFromService() {
  return genericGet('api/sites-pages-to-load')
}

export function getSitePropertiesFromService({ key }) {
  if (!key) {
    throw 'key is mandatory for this service'
  }
  if (key === 'robots.txt') {
    return {}
  }
  return genericGet('api/site-properties', { key })
}

export function getProductFromService({ siteKey, slug }) {
  return genericGet('api/product', { siteKey, slug })
}

export function createOrderInService(data) {
  return genericPost('api/checkout', data)
}

export function getProductForCheckoutFromService({
  siteKey,
  sellerKey,
  productsKey,
  queryParams,
}) {
  return genericPost('api/products-for-checkout', {
    siteKey,
    sellerKey,
    productsKey,
    queryParams,
  })
}

export function checkoutStripeCancel({ orderKey, sellerKey, stripeSessionId }) {
  return genericPost('api/checkout-stripe-cancel', {
    orderKey,
    sellerKey,
    stripeSessionId,
  })
}

export function submitNpsReviewToService(data) {
  return genericPost('api/nps-reviews', { data })
}

export function submitUserContactToService(data = {}) {
  return genericPost('api/contact', { ...data })
}


export function addContactToService(data) {
  return genericPost('api/contacts', { data })
}

export function getSiteProductsFromService({ siteKey }) {
  return genericGet('api/site-products', { siteKey })
}

export function getAllProductsFromService() {
  return genericGet('api/products-all')
}

export function getBrandFromService({ siteKey, slug, includeSiteProperties }) {
  return genericGet('api/brand', { siteKey, slug, includeSiteProperties })
}

export function getFeaturedBrandsFromService({ siteKey }) {
  return genericGet('api/brands-all', { site_key: siteKey , include_content: true})
}

export function getAllBrandFromService() {
  return genericGet('api/brands-all')
}

export function getBrandSlugsOnSiteFromService({ siteKey }) {
  return genericGet('api/brands-slugs-on-site', { siteKey })
}

export function getOrderConfirmationFromService({ key, stripeSessionId }) {
  return genericGet('api/confirmation', { key, stripeSessionId })
}
