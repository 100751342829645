

export const WEIGHT_TYPES = ['Light','Medium','Heavy'];
export const PRODUCT_CATEGORY_WINE = 'Wine';
export const PRODUCT_CATEGORY_SPIRITS = 'Spirits';
export const PRODUCT_CATEGORIES = [
  PRODUCT_CATEGORY_WINE,
  PRODUCT_CATEGORY_SPIRITS,
]

export const DELIEVERY_TYPE_SHIPPING = 'shipping'
export const DELIEVERY_TYPE_PICK_UP = 'pick-up'
export const DELIEVERY_TYPES = [
  DELIEVERY_TYPE_SHIPPING,
  DELIEVERY_TYPE_PICK_UP,
]
